.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(52, 52, 51, 0.334);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  min-width: 300px;
  min-height: 300px;
  background-color: palegoldenrod;
  box-shadow: (0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  box-shadow: 3px 3px 2px 1px rgba(5, 5, 50, 0.599);
  color: black;
}

.modalContent {
  background-color: rgba(238, 232, 170, 0.685);
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 30px; /* Adjust based on your needs */
  height: 26px; /* Adjust based on your needs */
  cursor: pointer;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeButton.black{
  background-color: black;
  color: gold;
}
.closeButton.gold{
  background-color: gold;
  color: black;
  border: none;
}

.closeButton:hover{
  font-weight: bold;
}

.hexagonSubmitBtn{

  width: 50px; /* Adjust based on your needs */
  height: 50px; /* Adjust based on your needs */
  margin-bottom: 10px;
  background-color: black;
  cursor: pointer;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: gold;
}

.hexagonSubmitBtn:hover{
  font-weight: bold;

}

.modalFooter{
  margin-bottom: 10px;
}
.errorMsg{
  color: black;
  text-align: center;
  background-color:rgba(229, 30, 30);
  padding-bottom: 5px;
}

.footerRedirect{

  color: blue;
  text-decoration-style: solid;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  z-index: 2;


}
/* Swap Modal Button Animation */

.footerRedirect::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 100%;
  height: 8px;
  z-index: -1;
}
.footerRedirect:hover::before {
  bottom: 0;
  height: 100%;
}
.footerRedirect::before {
  content: '';
  background-color:  gold;

  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 4px;
  z-index: -1;
  transition: all .3s ease-in-out;

}



/* Input animation Section */

.form-group {
  position: relative;
  width: 45%;

  z-index: 10;
}

.form-group input {

  padding: 10px;
  margin: 10px;
  font-size: 14px;
  border: none;
  width: 85%;
  border: black solid 1px;
  border-bottom: black solid 2px;
  border-right: black solid 2px;
  background-color: palegoldenrod;
  color: black;
  transition: 0.4s all ease;

}

.form-group input::placeholder {
  color: palegoldenrod;
  width: 100%;
}

.form-group .form-label {
  position: absolute;
  top: 20px;
  left: 16px;
  font-size: 14px;
  padding: 0px 10px;

  color: rgba(103, 101, 101, 0.836);
  pointer-events: none;
  transition: 0.4s all ease;
  font-style: italic;
}
.form-group input:focus {
  outline: none; /* Removes any default outlines */
  border-left: black solid 1px; /* Ensures no border is applied on the left */
  border-right: black solid 2px; /* Ensures no border is applied on the right */
  border-top: black solid 1px;
  border-bottom: black solid 2px; /* Keeps the bottom border */
}

.form-group input:focus + .form-label,
.form-group input:not(:placeholder-shown) + .form-label {
  transform: translate(-15px, -25px);
  font-size: 14px;
  color: gold;
  background: black;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;

}
/* END OF input animation section */
