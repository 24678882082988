.dashCont {
  height: 80vh;

  background-repeat: no-repeat;
  background-size: cover;
  height: 86vh;
}

.dashTop {
  padding: 25px;
  height: 220px;
  text-align: center;
}

.dashTextCont {
  text-align: center;

  width: 200px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.hexContainer {
  margin-left: auto;
  margin-right: auto;
  position: relative; /* This makes it a reference for absolutely positioned child elements */
  /* Optional: to ensure the container is also centered vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hexCenter {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  background-color: #e7c508;
  height: 220px;
  width: 300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hexborder {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  background-color: black;
  height: 225px;
  width: 305px;
  position: absolute;
  z-index: 1;
}

.hexText {
  width: 150px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 8;
  position: absolute;
  top: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  top: 0px;
}

.hexLeft {
  width: 0;
  height: 0;
  border-top: 95px solid transparent;
  border-right: 52px solid rgb(231, 197, 8);
  border-bottom: 95px solid transparent;
  z-index: 4;
  position: absolute;
  left: 10px;
}
.hexLeftBorder {
  width: 0;
  height: 0;
  border-top: 100px solid transparent;
  border-right: 57px solid black;
  border-bottom: 100px solid transparent;
  z-index: 1;
  position: absolute;
  left: 3px;
}

.hexright {
  width: 0;
  height: 0;
  border-top: 94px solid transparent;
  border-left: 54px solid rgb(231, 197, 8);
  border-bottom: 94px solid transparent;
  z-index: 4;
  position: absolute;
  right: 8px;
}
.hexrightBorder {
  width: 0;
  height: 0;
  border-top: 101px solid transparent;
  border-left: 59px solid black;
  border-bottom: 101px solid transparent;
  z-index: 2;
  position: absolute;
  right: 3px;
}

.dashSeperator {
  background-color: rgb(231, 197, 8);
  font-size: 20px;
  padding: 10px;
  width: 200px;
  text-align: center;
  color: black;
  position: relative;
  top: -25px;
  margin-left: auto;
  margin-right: auto;
  border: 5px ridge gold;
  font-weight: 800;
}

.dashText {
  text-align: center;
}

.small {
  font-size: 18px;
}

.big {
  font-size: 24px;
  font-weight: 700;
}

.dashBottom {
  background-color: black;
  color: rgb(160, 160, 160);
  height: 50%;
  text-align: center;
  width: 700px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border: 5px ridge gold;
  font-size: 18px;

}
.dashTaskCont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height:80%;
  justify-content: space-around;
  

}

.dashTask {

  width: 200px;
  margin-left: 20px;;
  display: flex;
  flex-direction: row;
}

.checkOffDash {
  width: 25px; /* Adjust based on your needs */
  height: 25px; /* Adjust based on your needs */
  cursor: pointer;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-right: 10px;
}
.whiteHex {
  background-color: rgb(208, 207, 207);
  color: black;
}

@media only screen and (max-width: 750px) {
  .dashCont {
    height: calc(100vh - 108px);

  }
  .hexCenter {
    height: 150px;
    width: 200px;
  }
  .hexborder {
    width: 205px;
    height: 155px;
  }
  .big {
    font-size: 18px;
  }
  .small {
    font-size: 14px;
  }
  .hexText {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    top: 0px;
  }
  .dashSeperator {
    font-size: 16px;
    padding: 5px;
    width: 100px;
  }
  .dashTop {
    height: 170px;
  }
  .dashBottom {
    height: calc(100vh - 370px);
    width: 350px;
  }
  .dashTaskCont {
    padding-left: 50px;
    align-items: center;
    overflow: hidden;
    text-align: center;

  }
  .dashTask {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: row;
    margin: 3px;
    margin-left: 15px;
    padding: 1px;
    text-align: center;

  }
  .checkOffDash {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  .whiteHex {
    height: 20px !important;
    width: 20px !important;
  }
}
