.taskPageBackground {
  padding: 40px;
  height: 77vh;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.taskPageCont {
  background-color: rgba(255, 255, 255, 0.925);
  height: 78vh;
  overflow: hidden;
  overflow-y: scroll;
  border: 3px solid black;
  padding: 30px;
  box-sizing: border-box;
  max-width: 700px;
  align-self: center;
}

.taskPageTitle {
  font-size: 40px;
  text-align: center;
  font-weight: 800;
  border-bottom: 5px solid black;
}

.noBlockTask {
  background-color: black;
  color: rgba(247, 211, 5, 0.758);
  width: 250px;
  height: 70px;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  margin: 5px;
  position: relative;
  display: flex;
  justify-content: center;
}
.tpBlockTitle {
  font-size: 22px;
  margin-bottom: 5px;
  text-align: center;
}
.TPaddTaskButton {
  background-color: #ffd700;
  color: black;
  width: 100px;
  height: 25px;
  text-align: center;
  position: absolute;
  bottom: -15px;
  right: 65px;
  border: 1px solid black;
  cursor: pointer;
}
.TPaddTaskButton:hover {
  font-weight: 900;
  background-color: #f7db39;
}

.taskBlockInfoCont {
  display: flex;
  flex-direction: column;
  color: rgba(247, 211, 5, 0.758);
  background-color: black;
  margin: 15px;
  padding: 10px;

  position: relative;
  width: 350px;
  border-radius: 2px;
  height: 125px;
}

#noBlockAssignedTitle {
  height: 75px;
}

.taskBlockRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid black;
}

.taskWeedayBar {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: 10px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-between;
}

.taskWeedayBar > div {
  text-align: center;
  font-size: 14px;
}
.taskBlockTime {
  text-align: center;
  font-size: 15px;
  color: rgba(247, 211, 5, 0.758);
}

.taskforBlockContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.taskTitle {
  width: 80%;
  margin: 10px;
  padding: 10px;
  text-align: center;
  border: 3px solid black;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.taskTitle:hover {
  font-weight: 900;
  background-color: #ffd900cd;
}
.ceContainer {
  box-shadow: -2px -2px rgba(255, 217, 0, 0.296);
  width: 400px;
}

.ceHeader {
  background-color: black;
  color: white;
  text-align: center;
  font-size: 40px;
  padding: 20px;
}

.taskFormGroup {
  margin: 30px;
}

.select-group select {
  background-color: palegoldenrod;
  color: black;
  font-size: 14px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.blockSelect select {
  margin-left: 40px;
  padding: 10px;
  width: 80%;
}

.select-group {
  position: relative;
}

.select-group option {
  padding: 10px;
  background: palegoldenrod;
}

.ceTaskBlockLabel {
  background-color: black;
  color: gold;
  width: 50px;
  padding: 3px;
  padding-left: 10px;
  position: absolute;
  font-size: 14px;
  z-index: 5;
  top: -18px;
  left: 30px;
}

.ceTaskReapeatBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 350px;
  margin: 20px;
}

.ceRepeatInput {
  width: 30px;
  background-color: palegoldenrod;
  height: 27px;
  text-align: center;
  line-height: 25px; /* Match input height */
  padding-left: 15px; /* Reset padding */
  border: 1px solid black; /* Example border */
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.ceRepeatLabel {
  background-color: black;
  color: gold;
  padding: 5px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  height: 20px;
}

.timeUnitSelect select {
  padding: 5px;
  width: 85px;
}

.buttonBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ceButton {
  background-color: black;
  color: gold;
  padding: 10px;
  margin: 10px;
  width: 75px;
  text-align: center;
}

.ceButton:hover {
  cursor: pointer;
  background-color: #ffd700;
  color: black;
  box-shadow: 2px 2px 2px black;
}

.tdTitle {
  color: white;
  font-size: 25px;

  width: 100%;
  text-align: center;
}

.tdHeader {
  background-color: black;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}

.tdStatusHexagon {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  height: 50px;
  width: 60px;

  justify-content: center;
  align-items: center;
}
.gold {
  background-color: gold;
}
.grey {
  background-color: white;
}
.greyOut {
  background-color: rgba(128, 128, 128, 0.39);
}
.tdstatusText {
  margin-top: 15px;
  font-size: 13px;
  text-align: center;
}
taskBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.taskBlockLabel {
  background-color: black;
  color: gold;
  text-align: center;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border-radius: 3px;
  padding: 3px;
}
.taskBlockTitle {
  background-color: palegoldenrod;
  text-align: center;
  border: 3px solid black;
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px;
  border-radius: 3px;
}
.tdButton {
  background-color: black;
  color: gold;
  border: none;
  padding: 10px;
  width: 120px;
  margin: 10px;
  margin-top: 30px;
  font-size: 12px;
  border: 1px solid gold;
}
.tdButton:hover {
  color: black;
  background-color: gold;
  border: 1px solid black;
}
.taskBtnBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.blackHex {
  background-color: black;
  color: gold;
}

@media only screen and (max-width: 750px) {
  .ceContainer {
    width: 100%;
  }
  #title {
    width: 200px !important;
  }
  .blockSelect select {
    width: 220px;
  }
  .ceTaskReapeatBar {
    width: 250px;
  }
  .ceRepeatLabel {
    height: 21px;
  }

  .csTaskTimeUnit{
    height:31px;
  }
  .taskBlockRow{
    flex-direction: column;
  }

  .taskBlockInfoCont{
    width:50vw;
    max-width: 300px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .taskforBlockContainer{
    align-items: center;
  }
  .TPaddTaskButton{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    bottom: -20px;
    left:auto;
    right: auto;
  }
  #noBlockButton{
    bottom: -40px;
  }
  .tpBlockTitle{
    font-size: 18px;
    font-weight: 700;
  }
  .taskPageBackground{
    height: calc(100vh - 190px);
  }
}
