body {
  margin: 0;
  font-family: "Patrick Hand", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  height: 100vh
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  /* This is to remove the default style of NavLinks */
  color: white;
  text-decoration: none;
}

.cursive {
  font-family: satisfy;
}
.handwriting {
  font-family: "Patrick Hand", cursive;
}
