.editProfileCont {
  display: flex;
  flex-direction: row;
  height: 300px;
  width: 450px;
  border: 3px solid black;
}

.previewImg {
  width: 50%;
  max-width: 200px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.selfieIMGPreview {
  max-width: 180px;
  max-height: 180px;
}
.EditUserForm > .form-group {
  width: 85%;
  margin-left: 20px;
}

.blackRectangleButton {
  background-color: black;
  color: gold;
  width: 100px;
  padding: 5px;
}

.yellowRectangleButton {
  background-color: gold;
  color: black;
  width: 100px;
  padding: 5px;
  cursor: pointer;
}
.EditUserForm > .blackRectangleButton {
  position: absolute;
  left: 80px;
  bottom: 20px;
  cursor: pointer;
}

.previewImg > .yellowRectangleButton {
  position: absolute;
  bottom: 20px;
}
.editProfileTitle {
  text-align: center;
  font-size: 30px;
  margin: 10px;
}

.error{
  color: red;
  margin: 5px;
  position: absolute;
  top: 25px;
  left: 10px;
}

@media (max-width: 500px) {
  .editProfileCont {
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    height: 450px; /* Use the full height of the viewport */
    width: 100%; /* Adjust the width to take full width */
    padding: 20px 0; /* Add some padding at the top and bottom */
  }

  .previewImg,
  .editProfileInputs {
    width: 100%; /* Make these elements take full available width */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    position: absolute;
    bottom: 100px;
  }

  .previewImg {
    height: 40%; /* Top section takes 40% of the height */
    padding: 5px;
    position: absolute;
    top: 10px;
  }

  .editProfileInputs {
    height: 40%; /* Middle section takes another 40% of the height */
    justify-content: flex-start; /* Align content to the start */
  }


  .EditUserForm > .blackRectangleButton {
    position: absolute;
    bottom: -80px;
    left: 15px;

  }
  .previewImg > .yellowRectangleButton {
    position: absolute;
    bottom: -257px;
    right: -25px;
  }


}
