.demoLink{
    text-align: center;
    cursor: pointer;
    margin: 5px;
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.545);
    color: rgb(153, 131, 9);
    padding: 0 .25rem;
    margin: 0 -.25rem;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;

}
.demoLink:hover{
    color: rgb(213, 182, 6);
    box-shadow: inset 200px 0 0 0 black;
}



/* This keeps the input field from turning pale blue when autofill happens */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px palegoldenrod inset;
    transition: background-color 5000s ease-in-out 0s;
}
