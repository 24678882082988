.tdSubtaskcont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tdToggleSubtask{
    background-color: black;
    color: gold;
    width: 80%;
    padding: 5px;
    text-align: center;
    margin-top: 30px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20px;
}

.tdSubtaskList{
    display: flex;
    background-color: palegoldenrod;
    width: 78%;
    align-items: center;
    justify-content: center;
    border: 2px solid black;

}

.addSTButton{
    background-color: black;
    color: gold;
    width: 40%;
    text-align: center;
    border-radius: 3px;
    margin: 10px;
    font-size: 20px;
}



.tdSubtaskList{
    display: flex;
    flex-direction: column;
}

.stTitle{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 5px;
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.STButtonBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.STButton{
    background-color: black;
    color: gold;
    padding: 3px;
    width: 50px;
    text-align: center;
}

.disabledButton{
    background-color: grey;
    color: rgba(0, 0, 0, 0.654);
    padding: 3px;
    width: 50px;
    text-align: center;
}

.stTitleInput{
    width: 250px;
    padding: 5px;
    padding-top: 15px;
    background-color: palegoldenrod
}
.addStForm{
    position: relative;
}
.addStTitleLabel{
    position: absolute;
    background-color: black;
    color: gold;
    padding-left: 10px;
    padding-right: 10px;
    top: -10px;
    left: -10px;
}

.addStForm{
    margin-top: 10px;
}

.subtaskDone{
    margin-top: 2px;
    height: 15px;
    width:15px;
    color: black;
    background-color: gold;
    border: 2px solid black;
    font-size: 10px;
    font-weight: 900;
}

.subtaskUndone{
    border: 2px solid black;
    height: 15px;
    width:15px;
    background-color: rgba(239, 233, 233, 0.47);
    margin-top: 2px;
}

.stOptionsMenu{
    cursor: pointer;
    width: 20px;

}

.stMenu{
    height: 70px;
    width: 120px;
    background-color: black;
    color:gold;
    position: absolute;
    right: -115px;
    top: 15px;
    z-index: 1000;
    border: 2px ridge gold;

}

.stMenu.active{
    display: block;
}

.stMenuOption{
    border-bottom: 1px solid rgba(255, 217, 0, 0.297);
    cursor: pointer;

}
.stMenuOption:hover{
    font-weight: 900;
}
