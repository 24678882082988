.confirmDelete{

    padding: 25px;
    text-align: center;
    width: 300px;
    border: 3px solid black;

}
.caution{
    font-size: 55px;
    text-align: center;
    font-weight: 800;
}

.deleteText{
    font-size: 25px;
}

.deleteBtnBar{
    margin: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.accountDeleteBtn{
    height: 35px;
}

.deleteBtn{
    background-color: black;
    color: gold;
    width: 100px;
}

.deleteBtn:hover{
    color: black;
    background-color: gold;
    cursor: pointer;

}
