.selfieHeader {
  margin: 10px;
}

.selfieTitle {
  font-size: 25px;
}

.selfieBtn {
  background-color: black;
  color: whitesmoke;
  margin: auto;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
}

.selfieBtn:hover {
    color: rgb(213, 182, 6);
    font-weight: bolder;
    border-top: 2px solid rgb(213, 182, 6);
    border-bottom: 2px solid rgb(213, 182, 6);
    cursor: pointer;
}
.selfie_container {
  height: 550px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.optionsBar {
  display: flex;
  width: 500px;
  height: 65px;
  flex-direction: row;
  justify-content: space-between;
  background-color: black;
  position: absolute;
  bottom: 0;
}
.nameBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 25px;

  width: 100%;
}
.selfieIMG {
  height: 400px;
  border: 4px solid black;
}
.selfieImgCont {
  margin-top: 25px;
}

@media (max-width: 500px){
  .selfie_container {
    height: 475px;
  }
  .selfieIMG {
    height: auto;
    width: 270px;
    position: absolute;
    left: 10px;

    border: 4px solid black;
  }
  .selfieImgCont {
    margin-top: 50px;
  }
  .nameBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    width: 100%;
    position: absolute;
    bottom: 75px;
    left: 15px;
  }
  .optionsBar{
    width: 100%;
    position: absolute;
    left: 0px;
  }
  .selfieBtn{
    width: 50px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
